<template>
  <ul
    ref="colorList"
    :class="containerClass"
    role="radiogroup"
    :aria-label="$t('product.color')">
    <li
      v-for="color in colorListSimplified"
      :key="color.title"
      class="color">
      <ColorItem
        :color="color"
        :isPdp="isPdp"
        :showColorTitle="false"
        role="radio"
        :aria-label="color.title"
        :aria-checked="color.preselected"
        tabindex="0"
        @updateColor="updateColor"
        @loadSizes="loadSizes"
        @sendColorName="sendColorName">
      </ColorItem>
    </li>
    <li
      v-if="showLinkToPDP"
      class="color-list__more">
      <div
        class="more-colors-link"
        :title="$t('global.view_more_colours')">
        <a
          v-if="productURL"
          :href="productURL">
          {{ linkToPDPText }}
        </a>
        <button
          v-else
          @click="handleMoreColorsClick">
          {{ linkToPDPText }}
        </button>
      </div>
    </li>
  </ul>
</template>

<script>
import ColorItem from 'CommonComponents/Product/Selector/Color/ColorItem.vue';
import { computed, inject, toRefs } from 'vue';
import useHtmlElementOverflow from 'CommonComposables/useOverflow';

export default {
  name: 'PreviewColorsComplex',
  components: {
    ColorItem,
  },
  props: {
    colorsCollection: {
      type: Array,
      required: true,
    },
    isPdp: {
      type: Boolean,
      required: true,
    },
    productUri: {
      type: String,
      default: '',
    },
    showExtraColorCountOnOverflow: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  emits: ['updateColor', 'loadSizes', 'sendColorName', 'moreColorsClick'],
  setup(props, { emit }) {
    const updateColor = (color) => {
      emit('updateColor', color);
    };
    const loadSizes = () => {
      emit('loadSizes');
    };
    const sendColorName = (colorName) => {
      emit('sendColorName', colorName);
    };
    const handleMoreColorsClick = () => {
      emit('moreColorsClick')
    }

    const { productUri } = toRefs(props);
    const colorList = ref(null);
    const { visibleWidth } = useHtmlElementOverflow(colorList, { ignoredItems: ['color-list__more']});

    const isOverflowing = computed(() => {
      const totalItems = props.colorsCollection.length;
      const itemWidth = colorList.value?.querySelector('li.color')?.offsetWidth;
      return totalItems * itemWidth > visibleWidth.value;
    });

    const visibleItems = computed(() => {
      const itemWidth = colorList.value?.querySelector('li.color')?.offsetWidth;
      return visibleWidth.value / itemWidth;
    })

    const showLinkToPDP = computed(() => {
      return (props.showExtraColorCountOnOverflow && isOverflowing.value) || (props.colorsCollection.length > 5 && !props.isPdp);
    });

    const colorListSimplified = computed(() => {
      const colorList = props.colorsCollection.filter(color => !color?.hideColor);
      if (props.showExtraColorCountOnOverflow && isOverflowing.value) {
        return colorList.splice(0, Math.floor(visibleItems.value - 2 ));
      }
      if (!showLinkToPDP.value || props.isPdp) {
        return colorList;
      }
      return colorList.splice(0, 5);
    });

    const linkToPDPText = computed(() => {
      if (props.showExtraColorCountOnOverflow) {
        return '+' + (props.colorsCollection?.length - Math.floor(visibleItems.value - 2 )).toString(); 
      }
      return '+' + (props.colorsCollection.length - 5).toString();
    });

    const productURL = computed(() => {
      if (productUri.value !== '') {
        return productUri.value;
      }
      const providedProductUri = inject('productUri', '');
      if (providedProductUri) {
        return providedProductUri;
      }
      return null;
    })

    const containerClass = computed(() => {
      return {
        'product_preview-variants': true,
        'pdp': props.isPdp,
        'color-list--single-line':  props.showExtraColorCountOnOverflow,
      }
    });

    return {
      containerClass,
      updateColor,
      loadSizes,
      showLinkToPDP,
      colorListSimplified,
      linkToPDPText,
      sendColorName,
      productURL,
      colorList,
      visibleItems,
      isOverflowing,
      handleMoreColorsClick,
      visibleWidth,
    };
  },
};
</script>
